import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {injectIntl, intlShape, defineMessages} from 'react-intl';

import decksLibraryContent from '../lib/libraries/mlforkids/index.jsx';
import mlforkidsTags from '../lib/libraries/mlforkids/mlforkids-tags';

import LibraryComponent from '../components/library/library.jsx';
import extensionIcon from '../components/action-menu/icon--sprite.svg';

import {connect} from 'react-redux';

import {
    closeMLForKidsLibrary
} from '../reducers/modals';

import {
    activateDeck
} from '../reducers/cards';

const messages = defineMessages({
    mlForKidsLibraryTitle: {
        defaultMessage: 'Machine Learning for Kids project templates',
        description: 'Template projects for Machine Learning for Kids',
        id: 'gui.mlforkidsLibrary.templates'
    }
});

class MLforKidsLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleItemSelect (item) {
        fetch(item.templateFile)
            .then(response => response.arrayBuffer())
            .then(projectdata => this.props.vm.loadProject(projectdata));
    }
    render () {
        const decksLibraryThumbnailData = decksLibraryContent.map(extension => ({
            rawURL: extension.iconURL || extensionIcon,
            id: extension.extensionId,
            name: extension.name,
            featured: extension.featured,
            tags: extension.tags,
            templateFile: extension.templateFile
        }));

        if (!this.props.visible) return null;
        return (
            <LibraryComponent
                filterable
                data={decksLibraryThumbnailData}
                id="mlforkidsLibrary"
                tags={mlforkidsTags}
                title={this.props.intl.formatMessage(messages.mlForKidsLibraryTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

MLforKidsLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateDeck: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    visible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.mlforkidsLibrary
});

const mapDispatchToProps = dispatch => ({
    onActivateDeck: id => dispatch(activateDeck(id)),
    onRequestClose: () => dispatch(closeMLForKidsLibrary())
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MLforKidsLibrary));
