import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import downloadBlob from '../lib/download-blob';


class ML4KStageVideoRecorder extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'toggleRecording'
        ]);

        this.state = {
            recording: props.isRecording
        };
    }

    toggleRecording () {
        if (this.state.recording) {
            this.props.vm.stopMLforKidsRecording()
                .then(blob => {
                    let extension = 'webm';
                    if (blob.type && blob.type.startsWith('video/')) {
                        extension = blob.type.substring('video/'.length);
                        if (extension.indexOf(';') > 0) {
                            extension = extension.substring(0, extension.indexOf(';'));
                        }
                    }
                    downloadBlob('ml4k-recording.' + extension, blob);
                })
                .catch(console.log);
        }
        else {
            this.props.vm.startMLforKidsRecording();
        }
        this.setState({recording: !this.props.isRecording});
    }

    render () {
        const {
            /* eslint-disable no-unused-vars */
            children,
            vm,
            /* eslint-enable no-unused-vars */
            ...props
        } = this.props;
        props.isRecording = this.state.recording;
        return this.props.children(this.toggleRecording, props);
    }
}

ML4KStageVideoRecorder.propTypes = {
    children: PropTypes.func,
    isRecording: PropTypes.bool,
    vm: PropTypes.shape({
        startMLforKidsRecording: PropTypes.func,
        stopMLforKidsRecording: PropTypes.func
    })
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    isRecording: state.scratchGui.vm._ml4kidsIsRecording
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(ML4KStageVideoRecorder);
