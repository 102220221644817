export default [
	{
		tag: 'text', intlLabel: {
			defaultMessage: 'Text projects',
			id: 'gui.mlforkidsTags.text'
		}
	},
	{
		tag: 'images', intlLabel: {
			defaultMessage: 'Image projects',
			id: 'gui.mlforkidsTags.images'
		}
	},
	{
		tag: 'numbers', intlLabel: {
			defaultMessage: 'Numbers projects',
			id: 'gui.mlforkidsTags.numbers'
		}
	},
	{
		tag: 'sounds', intlLabel: {
			defaultMessage: 'Sounds projects',
			id: 'gui.mlforkidsTags.sounds'
		}
	}
];
