import React from 'react';
import {FormattedMessage} from 'react-intl';

import smartClassroomImage from './project-smartclassroom.png';
import touristInfoImage from './project-touristinfo.png';
import headlinesImage from './project-headlines.png';
import chatbotsImage from './project-chatbots.png';
import twitterImage from './project-whattwitterthinks.png';
import snapImage from './project-snap.png';
import facelockImage from './project-facelock.png';
import mailmanMaxImage from './project-mailmanmax.png';
import carorcupImage from './project-carorcup.png';
import rockPaperScissorsImage from './project-rockpaperscissors.png';
import judgeABookImage from './project-judgeabook.png';
import locateLarryImage from './project-locatelarry.png';
import confusedImage from './project-confused.png';
import chameleonImage from './project-chameleon.png';
import shyPandaImage from './project-shypanda.png';
import virtualPetImage from './project-virtualpet.png';
import pacmanImage from './project-pacman.png';
import noughtsAndCrossesImage from './project-noughtsandcrosses.png';
import topTrumpsImage from './project-toptrumps.png';
import sortingOfficeImage from './project-sortingoffice.png';
import avoidTheMonsterImage from './project-avoidthemonster.png';
import findTheDuckImage from './project-findtheduck.png';
import alienLanguageImage from './project-alienlanguage.png';
import secretCodeImage from './project-secretcode.png';
import zombieEscapeImage from './project-zombieescape.png';
import kiwiOrStoatImage from './project-kiwiorstoat.png';
import inkBlotsImage from './project-inkblots.png';
import fooledImage from './project-fooled.png';
import laserEyesImage from './project-lasereyes.png';
import semaphoresImage from './project-semaphores.png';
import shootTheBugImage from './project-shootthebug.png';
import describeTheGlassImage from './project-describetheglass.png';
import handGesturesImage from './project-handgestures.png';
import quizShowImage from './project-quizshow.png';
import explainabilityImage from './project-explainability.png';
import pokemonImagesImage from './project-pokemonimages.png';
import pokemonStatsImage from './project-pokemonstatistics.png';

import smartClassroomTemplate from './templates/smart-classroom.sbx';
import smartClassroomEasyTemplate from './templates/smart-classroom-easy.sbx';
import touristInfoTemplate from './templates/tourist-info.sbx';
import touristInfoEasyTemplate from './templates/tourist-info-easy.sb3';
import headlinesTemplate from './templates/headlines.sbx';
import chatbotsTemplate from './templates/owl.sb3';
import twitterTemplate from './templates/what-does-twitter-think.sb3';
import snapTemplate from './templates/snap.sb3';
import facelockTemplate from './templates/facelock.sbx';
import mailmanMaxTemplate from './templates/mailman-max.sb3';
import carorcupTemplate from './templates/car-or-cup.sb3';
import rockPaperScissorsTemplate from './templates/rock-paper-scissors.sb3';
import judgeABookTemplate from './templates/judge-a-book.sb3';
import locateLarryTemplate from './templates/locate-larry.sb3';
import confusedTemplate from './templates/confused.sb3';
import chameleonTemplate from './templates/chameleon.sbx';
import shyPandaTemplate from './templates/shy-panda.sb3';
import virtualPetTemplate from './templates/flobzy.sb3';
import pacmanTemplate from './templates/pacman.sbx';
import noughtsAndCrossesTemplate from './templates/noughts-and-crosses.sb3';
import topTrumpsTemplate from './templates/top-trumps.sb3';
import sortingOfficeTemplate from './templates/sorting-office.sb3';
import avoidTheMonsterTemplate from './templates/avoid-the-monster.sb3';
import findTheDuckTemplate from './templates/find-the-duck.sb3';
import alienLanguageTemplate from './templates/alien-language.sb3';
import secretCodeTemplate from './templates/secret-code.sb3';
import zombieEscapeTemplate from './templates/zombie-escape.sb3';
import kiwiOrStoatTemplate from './templates/kiwi-stoat.sb3';
import inkBlotsTemplate from './templates/inkblots.sb3';
import fooledTemplate from './templates/fooled.sb3';
import laserEyesTemplate from './templates/laser-eyes.sb3';
import semaphoresTemplate from './templates/semaphores.sb3';
import shootTheBugTemplate from './templates/shoot-the-bug.sb3';
import describeTheGlassTemplate from './templates/describe-the-glass.sb3';
import handGesturesTemplate from './templates/hand-gestures.sb3';
import quizShowTemplate from './templates/quiz-show.sb3';
import explainabilityTemplate from './templates/explainability.sb3';
import pokemonImagesTemplate from './templates/pokemon-images.sb3';
import pokemonStatsTemplate from './templates/pokemon-statistics.sb3';


export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="Describe the glass"
                description="Template Scratch file for the 'Describe the glass' project"
                id="gui.mlforkids.describetheglass.name"
            />
        ),
        extensionId: 'describetheglass',
        iconURL: describeTheGlassImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to predict your answers"
                description="Description for the 'Describe the glass' template"
                id="gui.mlforkids.describetheglass.description"
            />
        ),
        featured: true,
        templateFile: describeTheGlassTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Smart Classroom"
                description="Template Scratch file for the 'Smart classroom' project"
                id="gui.mlforkids.smartclassroom.name"
            />
        ),
        extensionId: 'smartclassroom',
        iconURL: smartClassroomImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Create a smart assistant that lets you control virtual devices"
                description="Description for the 'Smart Classroom' template"
                id="gui.mlforkids.smartclassroom.description"
            />
        ),
        featured: true,
        templateFile: smartClassroomTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Smart Classroom (easy)"
                description="Template Scratch file for the 'Smart classroom' project"
                id="gui.mlforkids.smartclassroomeasy.name"
            />
        ),
        extensionId: 'smartclassroomeasy',
        iconURL: smartClassroomImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Shorter version of the Smart Classroom project"
                description="Description for the 'Smart Classroom' template"
                id="gui.mlforkids.smartclassroomeasy.description"
            />
        ),
        featured: true,
        templateFile: smartClassroomEasyTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Tourist Info"
                description="Template Scratch file for the 'Tourist Info' project"
                id="gui.mlforkids.touristinfo.name"
            />
        ),
        extensionId: 'touristinfo',
        iconURL: touristInfoImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Create a mobile app to recommend holiday destinations"
                description="Description for the 'Tourist Info' template"
                id="gui.mlforkids.touristinfo.description"
            />
        ),
        featured: true,
        templateFile: touristInfoTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Tourist Info (easy)"
                description="Template Scratch file for the 'Tourist Info' project"
                id="gui.mlforkids.touristinfoeasy.name"
            />
        ),
        extensionId: 'touristinfoeasy',
        iconURL: touristInfoImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Shorter version of the Tourist Info project"
                description="Description for the 'Tourist Info' template"
                id="gui.mlforkids.touristinfoeasy.description"
            />
        ),
        featured: true,
        templateFile: touristInfoEasyTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Sorting Office"
                description="Template Scratch file for the 'Sorting Office' project"
                id="gui.mlforkids.sortingoffice.name"
            />
        ),
        extensionId: 'sortingoffice',
        iconURL: sortingOfficeImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a post offce that can recognise handwriting"
                description="Description for the 'Sorting Office' template"
                id="gui.mlforkids.sortingoffice.description"
            />
        ),
        featured: true,
        templateFile: sortingOfficeTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Headlines"
                description="Template Scratch file for the 'Headlines' project"
                id="gui.mlforkids.headlines.name"
            />
        ),
        extensionId: 'headlines',
        iconURL: headlinesImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Test the computer's ability to recognise use of language"
                description="Description for the 'Headlines' template"
                id="gui.mlforkids.headlines.description"
            />
        ),
        featured: true,
        templateFile: headlinesTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Owls"
                description="Template Scratch file for the 'Chatbots' project"
                id="gui.mlforkids.owls.name"
            />
        ),
        extensionId: 'owls',
        iconURL: chatbotsImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Make a chatbot that can answer your questions"
                description="Description for the 'Owls' template"
                id="gui.mlforkids.owls.description"
            />
        ),
        featured: true,
        templateFile: chatbotsTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="What does Twitter think?"
                description="Template Scratch file for the 'What does Twitter think?' project"
                id="gui.mlforkids.whattwitterthinks.name"
            />
        ),
        extensionId: 'whattwitterthinks',
        iconURL: twitterImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Perform sentiment analysis on tweets"
                description="Description for the 'What does Twitter think?' template"
                id="gui.mlforkids.whattwitterthinks.description"
            />
        ),
        featured: true,
        templateFile: twitterTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Snap"
                description="Template Scratch file for the 'Snap' project"
                id="gui.mlforkids.snap.name"
            />
        ),
        extensionId: 'snap',
        iconURL: snapImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a card game that learns to recognise your card"
                description="Description for the 'Snap' template"
                id="gui.mlforkids.snap.description"
            />
        ),
        featured: true,
        templateFile: snapTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Face Lock"
                description="Template Scratch file for the 'Face Lock' project"
                id="gui.mlforkids.facelock.name"
            />
        ),
        extensionId: 'facelock',
        iconURL: facelockImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a phone that you can unlock with your face"
                description="Description for the 'Face Lock' template"
                id="gui.mlforkids.facelock.description"
            />
        ),
        featured: true,
        templateFile: facelockTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Mailman Max"
                description="Template Scratch file for the 'Mailman Max' project"
                id="gui.mlforkids.mailmanmax.name"
            />
        ),
        extensionId: 'mailmanmax',
        iconURL: mailmanMaxImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a post office that can recognise handwriting"
                description="Description for the 'Mailman Max' template"
                id="gui.mlforkids.mailmanmax.description"
            />
        ),
        featured: true,
        templateFile: mailmanMaxTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Car or Cup"
                description="Template Scratch file for the 'Car or Cup' project"
                id="gui.mlforkids.carorcup.name"
            />
        ),
        extensionId: 'carorcup',
        iconURL: carorcupImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Train a computer to be able to sort photos"
                description="Description for the 'Car or Cup' template"
                id="gui.mlforkids.carorcup.description"
            />
        ),
        featured: true,
        templateFile: carorcupTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Rock Paper Scissors"
                description="Template Scratch file for the 'Rock Paper Scissors' project"
                id="gui.mlforkids.rockpaperscissors.name"
            />
        ),
        extensionId: 'rockpaperscissors',
        iconURL: rockPaperScissorsImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a game that can recognise hand shapes"
                description="Description for the 'Rock Paper Scissors' template"
                id="gui.mlforkids.rockpaperscissors.description"
            />
        ),
        featured: true,
        templateFile: rockPaperScissorsTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Judge a book"
                description="Template Scratch file for the 'Judge a book' project"
                id="gui.mlforkids.judgeabook.name"
            />
        ),
        extensionId: 'judgeabook',
        iconURL: judgeABookImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a game that can recognise a book type by its cover"
                description="Description for the 'Judge a book' template"
                id="gui.mlforkids.judgeabook.description"
            />
        ),
        featured: true,
        templateFile: judgeABookTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Locate Larry"
                description="Template Scratch file for the 'Locate Larry' project"
                id="gui.mlforkids.locatelarry.name"
            />
        ),
        extensionId: 'locatelarry',
        iconURL: locateLarryImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a game that can find where Larry is hiding"
                description="Description for the 'Locate Larry' template"
                id="gui.mlforkids.locatelarry.description"
            />
        ),
        featured: true,
        templateFile: locateLarryTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Confused"
                description="Template Scratch file for the 'Confused' project"
                id="gui.mlforkids.confused.name"
            />
        ),
        extensionId: 'confused',
        iconURL: confusedImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a project to test an image classifier"
                description="Description for the 'Confused' template"
                id="gui.mlforkids.confused.description"
            />
        ),
        featured: true,
        templateFile: confusedTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Chameleon"
                description="Template Scratch file for the 'Chameleon' project"
                id="gui.mlforkids.chameleon.name"
            />
        ),
        extensionId: 'chameleon',
        iconURL: chameleonImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a chameleon that changes colour to match the background"
                description="Description for the 'Chameleon' template"
                id="gui.mlforkids.chameleon.description"
            />
        ),
        featured: true,
        templateFile: chameleonTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Shy Panda"
                description="Template Scratch file for the 'Shy Panda' project"
                id="gui.mlforkids.shypanda.name"
            />
        ),
        extensionId: 'shypanda',
        iconURL: shyPandaImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a panda that will only dance when you're not watching"
                description="Description for the 'Shy Panda' template"
                id="gui.mlforkids.shypanda.description"
            />
        ),
        featured: true,
        templateFile: shyPandaTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Fooled"
                description="Template Scratch file for the 'Fooled' project"
                id="gui.mlforkids.fooled.name"
            />
        ),
        extensionId: 'fooled',
        iconURL: fooledImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a game to test a machine learning model"
                description="Description for the 'Fooled' template"
                id="gui.mlforkids.fooled.description"
            />
        ),
        featured: true,
        templateFile: fooledTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Virtual Pet"
                description="Template Scratch file for the 'Virtual Pet' project"
                id="gui.mlforkids.virtualpet.name"
            />
        ),
        extensionId: 'virtualpet',
        iconURL: virtualPetImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Make a virtual pet that you have to look after"
                description="Description for the 'Virtual Pet' template"
                id="gui.mlforkids.virtualpet.description"
            />
        ),
        featured: true,
        templateFile: virtualPetTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pac-Man"
                description="Template Scratch file for the 'Pac-Man' project"
                id="gui.mlforkids.pacman.name"
            />
        ),
        extensionId: 'pacman',
        iconURL: pacmanImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Create a Pac-Man game that learns how to avoid the ghost"
                description="Description for the 'Pac-Man' template"
                id="gui.mlforkids.pacman.description"
            />
        ),
        featured: true,
        templateFile: pacmanTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Noughts and Crosses"
                description="Template Scratch file for the 'Noughts and Crosses' project"
                id="gui.mlforkids.noughtsandcrosses.name"
            />
        ),
        extensionId: 'noughtsandcrosses',
        iconURL: noughtsAndCrossesImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Create a noughts and crosses game that learns how to beat you"
                description="Description for the 'Noughts and Crosses' template"
                id="gui.mlforkids.noughtsandcrosses.description"
            />
        ),
        featured: true,
        templateFile: noughtsAndCrossesTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Top Trumps"
                description="Template Scratch file for the 'Top Trumps' project"
                id="gui.mlforkids.toptrumps.name"
            />
        ),
        extensionId: 'toptrumps',
        iconURL: topTrumpsImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Make a game that learns how to play Top Trumps"
                description="Description for the 'Top Trumps' template"
                id="gui.mlforkids.toptrumps.description"
            />
        ),
        featured: true,
        templateFile: topTrumpsTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Avoid the monster"
                description="Template Scratch file for the 'Avoid the monster' project"
                id="gui.mlforkids.avoidthemonster.name"
            />
        ),
        extensionId: 'avoidthemonster',
        iconURL: avoidTheMonsterImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Create a game that learns how to avoid the monster"
                description="Description for the 'Avoid the monster' template"
                id="gui.mlforkids.avoidthemonster.description"
            />
        ),
        featured: true,
        templateFile: avoidTheMonsterTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Find the Duck"
                description="Template Scratch file for the 'Find the Duck' project"
                id="gui.mlforkids.findtheduck.name"
            />
        ),
        extensionId: 'findtheduck',
        iconURL: findTheDuckImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to find where the duck is hiding"
                description="Description for the 'Find the duck' template"
                id="gui.mlforkids.findtheduck.description"
            />
        ),
        featured: true,
        templateFile: findTheDuckTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Alien Language"
                description="Template Scratch file for the 'Alien Language' project"
                id="gui.mlforkids.alienlanguage.name"
            />
        ),
        extensionId: 'alienlanguage',
        iconURL: alienLanguageImage,
        tags: ['sounds'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to understand an alien language"
                description="Description for the 'Alien Language' template"
                id="gui.mlforkids.alienlanguage.description"
            />
        ),
        featured: true,
        templateFile: alienLanguageTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Secret Code"
                description="Template Scratch file for the 'Secret Code' project"
                id="gui.mlforkids.secretcode.name"
            />
        ),
        extensionId: 'secretcode',
        iconURL: secretCodeImage,
        tags: ['sounds'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to understand a secret spy code"
                description="Description for the 'Secret Code' template"
                id="gui.mlforkids.secretcode.description"
            />
        ),
        featured: true,
        templateFile: secretCodeTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Zombie Escape"
                description="Template Scratch file for the 'Zombie Escape' project"
                id="gui.mlforkids.zombieescape.name"
            />
        ),
        extensionId: 'zombieescape',
        iconURL: zombieEscapeImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to help you escape the zombies"
                description="Description for the 'Zombie Escape' template"
                id="gui.mlforkids.zombieescape.description"
            />
        ),
        featured: true,
        templateFile: zombieEscapeTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Kiwi or Stoat"
                description="Template Scratch file for the 'Kiwi or Stoat' project"
                id="gui.mlforkids.kiwiorstoat.name"
            />
        ),
        extensionId: 'kiwiorstoat',
        iconURL: kiwiOrStoatImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to identify the animals"
                description="Description for the 'Kiwi or Stoat' template"
                id="gui.mlforkids.kiwiorstoat.description"
            />
        ),
        featured: true,
        templateFile: kiwiOrStoatTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Ink blots"
                description="Template Scratch file for the 'Ink blots' project"
                id="gui.mlforkids.inkblots.name"
            />
        ),
        extensionId: 'inkblots',
        iconURL: inkBlotsImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to identify objects in ink blots"
                description="Description for the 'Ink blots' template"
                id="gui.mlforkids.inkblots.description"
            />
        ),
        featured: true,
        templateFile: inkBlotsTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Laser Eyes"
                description="Template Scratch file for the 'Laser Eyes' project"
                id="gui.mlforkids.lasereyes.name"
            />
        ),
        extensionId: 'lasereyes',
        iconURL: laserEyesImage,
        tags: ['sounds'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to recognize commands"
                description="Description for the 'Laser Eyes' template"
                id="gui.mlforkids.lasereyes.description"
            />
        ),
        featured: true,
        templateFile: laserEyesTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Semaphores"
                description="Template Scratch file for the 'Semaphores' project"
                id="gui.mlforkids.semaphores.name"
            />
        ),
        extensionId: 'semaphores',
        iconURL: semaphoresImage,
        tags: ['sounds'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to recognize commands"
                description="Description for the 'Semaphores' template"
                id="gui.mlforkids.semaphores.description"
            />
        ),
        featured: true,
        templateFile: semaphoresTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Shoot the bug"
                description="Template Scratch file for the 'Shoot the bug' project"
                id="gui.mlforkids.shootthebug.name"
            />
        ),
        extensionId: 'shootthebug',
        iconURL: shootTheBugImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to help you shoot the bug"
                description="Description for the 'Shoot the bug' template"
                id="gui.mlforkids.shootthebug.description"
            />
        ),
        featured: true,
        templateFile: shootTheBugTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Hand gestures"
                description="Template Scratch file for the 'Hand gestures' project"
                id="gui.mlforkids.handgestures.name"
            />
        ),
        extensionId: 'handgestures',
        iconURL: handGesturesImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Train the computer to recognize hand gestures"
                description="Description for the 'Hand gestures' template"
                id="gui.mlforkids.handgestures.description"
            />
        ),
        featured: true,
        templateFile: handGesturesTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Quiz Show"
                description="Template Scratch file for the 'Quiz Show' project"
                id="gui.mlforkids.quizshow.name"
            />
        ),
        extensionId: 'quizshow',
        iconURL: quizShowImage,
        tags: ['text'],
        description: (
            <FormattedMessage
                defaultMessage="Use a machine learning model to find answers"
                description="Description for the 'Quiz Show' template"
                id="gui.mlforkids.quizshow.description"
            />
        ),
        featured: true,
        templateFile: quizShowTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Explainability"
                description="Template Scratch file for the 'Explainability' project"
                id="gui.mlforkids.explainability.name"
            />
        ),
        extensionId: 'explainability',
        iconURL: explainabilityImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Explain your image machine learning model"
                description="Description for the 'Explainability' template"
                id="gui.mlforkids.explainability.description"
            />
        ),
        featured: true,
        templateFile: explainabilityTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pokemon images"
                description="Template Scratch file for the 'Pokemon images' project"
                id="gui.mlforkids.pokemonimages.name"
            />
        ),
        extensionId: 'pokemonimages',
        iconURL: pokemonImagesImage,
        tags: ['images'],
        description: (
            <FormattedMessage
                defaultMessage="Use a machine learning model to recognise Pokemon"
                description="Description for the 'Pokemon images' template"
                id="gui.mlforkids.pokemonimages.description"
            />
        ),
        featured: true,
        templateFile: pokemonImagesTemplate
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pokemon statistics"
                description="Template Scratch file for the 'Pokemon statistics' project"
                id="gui.mlforkids.pokemonstatistics.name"
            />
        ),
        extensionId: 'pokemonstatistics',
        iconURL: pokemonStatsImage,
        tags: ['numbers'],
        description: (
            <FormattedMessage
                defaultMessage="Use a machine learning model to recognise Pokemon"
                description="Description for the 'Pokemon statistics' template"
                id="gui.mlforkids.pokemonstatistics.description"
            />
        ),
        featured: true,
        templateFile: pokemonStatsTemplate
    }
];
